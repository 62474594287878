import React, { useState } from 'react';
import '../App.css';
import Navbar from "../Components/Navbar";
import Eth from '../Images/Coinseth.png';
import BNB from '../Images/bnb3.png';
import Matic from '../Images/matics.png';
import USDT from '../Images/usdt2.png';
import { useNavigate } from "react-router-dom";





function Dashboard() {
    const userDataString = localStorage.getItem('userData');
    const userData = JSON.parse(userDataString);
    const [buttonText, setButtonText] = useState('Copy');
    const navigate = useNavigate();


    const handleCopy = async () => {
        try {
          await navigator.clipboard.writeText( userData.wallet_address );
          setButtonText('Copied!');
          setTimeout(() => setButtonText('Copy'), 2000); // Reset after 2 seconds
        } catch (err) {
          console.error('Failed to copy text:', err);
          setButtonText('Error!'); // Display error message
        }
    };

    const handleLogout = () => {
        window.localStorage.clear();
        navigate('/login');
        // window.location.href = '/login';
      };
  
    if (userData) {
      console.log('User Data:', userData);
      // Use the userData object to display data
      return (
        <div>
            <Navbar/>
         <div class=" text-center text-justify p-5 m-1 ml-5 mr-5 ">
          <h1 class="text-1xl text-center mt-1 font-extrabold font-poppins sm:text-2xl sm:font-extrabold">  
            Hello <span class="text-byson-blue">{userData.email}</span>  
          </h1>
          <p class="mt-1 text-center font-light text-sm sm:mt-1.7 sm:mb-1 ">
            You have officially joined our Waitlist 
          </p>  
          
         </div>

            
        <div class="grid grid-cols-6 gap-2 sm:gap-2 ">
            
           <div class="grid col-start-1 col-span-6 shadow-lg p- m-1 sm:col-start-2 sm:col-span-4  sm:p-5 sm:m-5 rounded-lg">
           <div class="flex flex-col m-5 mt-10 mb-10  md:flex md:flex-row  sm:flex sm:flex-row ">


                
        <div class="basis-1/2  ml- pl- pt- ">  
          <div class="grid place-self-center sm:mt-9 grid-cols-2 gap-2 sm:gap-4 ">
           <div class="grid bg-byson-blue p-1 pr-6  rounded-lg text-white ">
           <div class="flex">
           <img class="p-2" src={Eth} alt="ETH" width="60%" height=""/>   
            <div>
              <h1 class="text-1xl  mt-1 font-semiabold font-poppins sm:text-2xl sm:font-semibold">  
               ETH 
              </h1>
              <p class="font-light text-sm sm:mb-1 ">
                Ethereum
              </p>
             </div>
           </div>
           <div class="pl-3 mt-4">
              <h1 class="text-1xl  mt-1 font-semiabold font-poppins sm:text-2xl sm:font-semibold">  
                 $ 0
              </h1>
              <p class="font-light text-sm sm:mb-1 ">
                0 ETH
              </p>
           </div>  
            </div>
            
            
            <div class="grid bg-byson-blue p-1 pr-6  rounded-lg text-white ">
           <div class="flex">
           <img class="p-2" src={BNB} alt="ETH" width="60%" height=""/>   
            <div>
              <h1 class="text-1xl  mt-1 font-semiabold font-poppins sm:text-2xl sm:font-semibold">  
               BNB 
              </h1>
              <p class="font-light text-sm sm:mb-1 ">
                BNB
              </p>
             </div>
           </div>
           <div class="pl-3 mt-4">
              <h1 class="text-1xl  mt-1 font-semiabold font-poppins sm:text-2xl sm:font-semibold">  
                 $ 0
              </h1>
              <p class="font-light text-sm sm:mb-1 ">
                0 BNB
              </p>
           </div>  
            </div>
            <div class="grid bg-byson-blue p-1 pr-6  rounded-lg text-white ">
           <div class="flex">
           <img class="p-2" src={Matic} alt="ETH" width="60%" height=""/>   
            <div>
              <h1 class="text-1xl  mt-1 font-semiabold font-poppins sm:text-2xl sm:font-semibold">  
               MATIC
              </h1>
              <p class="font-light text-sm sm:mb-1 ">
                Polygon
              </p>
             </div>
           </div>
           <div class="pl-3 mt-4">
              <h1 class="text-1xl  mt-1 font-semiabold font-poppins sm:text-2xl sm:font-semibold">  
                 $ 0
              </h1>
              <p class="font-light text-sm sm:mb-1 ">
                0 MATIC
              </p>
           </div>  
            </div>
            <div class="grid bg-byson-blue p-1 pr-6  rounded-lg text-white ">
           <div class="flex">
           <img class="p-2" src={USDT} alt="ETH" width="60%" height=""/>   
            <div>
              <h1 class="text-1xl  mt-1 font-semiabold font-poppins sm:text-2xl sm:font-semibold">  
               USDT 
              </h1>
              <p class="font-light text-sm sm:mb-1 ">
                Tether
              </p>
             </div>
           </div>
           <div class="pl-3 mt-4">
              <h1 class="text-1xl  mt-1 font-semiabold font-poppins sm:text-2xl sm:font-semibold">  
                 $ 0
              </h1>
              <p class="font-light text-sm sm:mb-1 ">
                0 USDT
              </p>
           </div>  
            </div>
         </div>
        </div>


        <div class="basis-1/2  ml- pl- pt- mt-4 sm:mt-0 sm:ml-2">
         <div class="grid place-self-center sm:mt-9 grid-cols-1 gap-4 ">
         <div class="grid border p-1 pl-3  rounded-lg ">
           <div class="flex"> 
            <div>
              <h1 class="text-1xl  mt-1 font-semiabold font-poppins sm:text-1xl sm:font-semibold">  
               Address 
              </h1>
              <p class="hidden font-light text-sm  md:block ">
                {userData.wallet_address}
              </p>
              <p class="font-light text-sm  sm:block md:hidden ">
              {userData.masked_wallet_address}
              </p>
              <button onClick={handleCopy} class="bg-byson-blue text-white p-1 font-light text-sm w-20 rounded-lg">
                {buttonText}
              </button>
              <p class="font-light text-sm sm:mb-1 ">
               {userData.email}
              </p>
             </div>
             {/* <img class="p-2" src={USDT} alt="ETH" width="10%" height=""/>  */}
           </div>
           
            </div>
           
            <div class="grid border p-1 pl-3  rounded-lg ">
           <div class="flex"> 
            <div>
              <h1 class="text-1xl   mb-1 font-semiabold font-poppins sm:text-1xl sm:font-semibold">  
               SEND 
              </h1>
             </div>
             {/* <img class="p-2" src={USDT} alt="ETH" width="10%" height=""/>  */}
           </div>
           
            </div>


         <div class="basis-1/2  ml- pl- pt- ">  
          <div class="grid place-self-center  grid-cols-2 gap-2 sm:gap-4 ">
           <div class="grid border p-1 pl-3 pr-6  rounded-lg ">
           <div class="flex">   
            <div>
              <h1 class="text-1xl  mt-1 mb-1 font-semiabold font-poppins sm:text-1xl sm:font-semibold">  
                 BUY 
              </h1>
              <p class="font-light text-sm sm:mb-1 ">
               Coming Soon
              </p>
             </div>
           </div>  
           </div>
            
            
            <div class="grid border p-1 pl-3 pr-6  rounded-lg  ">
           <div class="flex">   
            <div>
            <h1 class="text-1xl  mt-1 mb-1 font-semiabold font-poppins sm:text-1xl sm:font-semibold">  
                 SELL 
              </h1>
              <p class="font-light text-sm sm:mb-1 ">
               Coming Soon
              </p>
             </div>
           </div> 
            </div>
         </div>
        </div>

            <div class="grid border p-1 pl-3  rounded-lg ">
           <div class="flex"> 
            <div>
              <h1 class="text-1xl  mt-1 font-semiabold font-poppins sm:text-1xl sm:font-semibold">  
               Activities 
              </h1>
              
             </div>
             {/* <img class="p-2" src={USDT} alt="ETH" width="10%" height=""/>  */}
           </div>
           
            </div>
            
            
         </div>
         
        </div>
        
      </div>
      <button class="bg-byson-blue text-white p-1 ml-4 mr-4 mb-4 rounded-lg" onClick={handleLogout}>Logout</button>
         

    
        </div>
      </div>

      <div class="grid   grid-cols-none sm:grid-cols-2 m-3 justify-items-center">
       <div class=" ">
        <a href='/tou'>
          <p class="mt-2 text-center font-light text-sm sm:mt-1.7 sm:mb-1 ">
            Terms of Use
          </p></a>
       </div>
       <div class=" ">
       <a href='/policy'>
          <p class="mt-2 text-center font-light text-sm sm:mt-1.7 sm:mb-1 ">
            Policy
          </p></a>
       </div>
       
      </div>



          {/* <h1>Welcome, {userData.email}</h1>
          <p>Address Chain: {userData.address_chain}</p>
          <p>Auth Count: {userData.auth_count}</p>
          <p>Wallet: {userData.masked_wallet_address}</p>
          Display other properties as needed */}
        </div>
      );
    } else {
      console.log('You are not logged in.');
      // Handle the case where no data is found
      return (
        <div>
         <Navbar/>

          <div class=" text-center text-justify p-5 m-3 ml-5 mr-5 ">
          <h1 class="text-2xl text-center mt-1 font-extrabold font-poppins sm:text-5xl sm:font-extrabold">  
            You are Currently Logged Out. Please Login to access your dashboard 
          </h1>
          
          <label class=" grid justify-items-center ">
           
          <button onClick={() => navigate('/Login')} class="bg-byson-blue text-white  p-1  p-2 mb-5 w-60 rounded-lg">
              Login
           </button>
          </label>
          
          
            
         </div>
        </div>
      );
    }
  }
  export default Dashboard;





