import React from "react";
import { useState } from "react";
import 'react-router-dom'
import { Link } from 'react-router-dom';
import '../App.css';
import byfy from '../Images/byfy.png';
import Modal from 'react-modal';



function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <div class="flex flex-row m-3 content-center text-dark-green border-b-2 pb-2  ">
      <a href="/">
        <div class=" basis-1/2 flex flex-row ml-3 items-center ">
        <img src={byfy} alt="d" width="12%" height="" />
        <h2 class="font-extrabold hover:text-byson-blue text-byson-blue font-extrabold sm:font-extrabold text-1xl ml-2" >Byson Finance</h2>
      </div> 
      </a>
      
     

      <div class="sm:hidden flex flex-row basis-1/2 justify-end">
        <button class="flex flex-row" type="button" onClick={openModal} >  
        
        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
           <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" />
        </svg> */}
        </button>
      </div>
     

    </div>

  );
}

export default Navbar;