import React from "react";
import '../App.css';
import Navbar from "../Components/Navbar";
import { useNavigate } from "react-router-dom";






function Policy() {
      return (
        <div>
          <Navbar/>
          <div class=" text-justify p-1 m-1 ml-2 mr-2 sm:p-5 sm:m-3 sm:ml-5 sm:mr-5 ">
          <h1 class="text-1xl mt-1 font-extrabold font-poppins sm:text-2xl sm:font-extrabold">  
             Byson Finance Privacy Policy
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
          Byson.Finance ("we", "us", "our") is committed to protecting your privacy. This Privacy Policy
           outlines how we collect, use, disclose, and safeguard your information when you use our cryptocurrency 
           exchange platform ("Platform"). By using our Platform, you agree to the collection 
           and use of information in accordance with this Privacy Policy. See the <a href="/tou" class=" text-byson-blue "> Terms and Conditions </a>.
          </p>
          <br/>
          <h1 class="text-1xl  mt-1 font-extrabold font-poppins sm:text-xl sm:font-extrabold">  
           Personal Information 
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
             When you create an account using social login credentials, we may collect the following personal information:
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. Name
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. Email address
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. Social media profile information
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. Contact information
          </p>
          <br/>
          <h1 class="text-1xl  mt-1 font-extrabold font-poppins sm:text-xl sm:font-extrabold">  
            Non-Personal Information
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
           We may collect non-personal information about you whenever you interact with our Platform, including:
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. Browser Type
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. Operating System
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. Device information
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. IP address
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. Pages viewed and time spent on the Platform
          </p>
          <br/>
          <h1 class="text-1xl mt-1 font-extrabold font-poppins sm:text-xl sm:font-extrabold">  
             Cookies and Tracking Technologies
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
          We use cookies and similar tracking technologies to enhance your experience on our Platform. 
          Cookies are small data files stored on your device. You can choose to disable cookies through your browser 
          settings, but this may affect the functionality of our Platform.
          </p>
          <br/>
          <h1 class="text-1xl  mt-1 font-extrabold font-poppins sm:text-xl sm:font-extrabold">  
            How We Use Your Information
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
           We use the information we collect for various purposes, including:
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. To provide, operate, and maintain our Platform
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. To improve, personalize, and expand our Platform
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. To understand and analyze how you use our Platform
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. To develop new products, services, features, and functionality
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. To communicate with you, either directly or through one of our partners, including for 
            customer service, to provide you with updates and other information relating to the Platform, 
            and for marketing and promotional purposes
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. To process your transactions and manage your account
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. To detect and prevent fraudulent transactions and other illegal activities
          </p>
          <br/>
          <h1 class="text-1xl  mt-1 font-extrabold font-poppins sm:text-xl sm:font-extrabold">  
            How We Share Your Information
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
           We may share your information in the following situations:
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. With Service Providers: We may share your information with third-party service providers to 
            perform services on our behalf, such as payment processing, data analysis, email delivery, 
            and hosting services.
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. For Legal Reasons: We may disclose your information if required to do so by law or in response
             to valid requests by public authorities (e.g., a court or government agency).
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets,
             your information may be transferred to the new owner.
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. With Your Consent: We may share your information with other parties with your
             consent or at your direction.
          </p>
          <br/>
          <h1 class="text-1xl mt-1 font-extrabold font-poppins sm:text-xl sm:font-extrabold">  
          Data Security
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
           We implement appropriate technical and organizational measures to protect your information from unauthorized 
           access, use, or disclosure. However, no method of transmission over the Internet or method of electronic storage
           is completely secure, and we cannot guarantee absolute security.
          </p>
          <br/>
          <h1 class="text-1xl mt-1 font-extrabold font-poppins sm:text-xl sm:font-extrabold">  
           Retention of Your Information
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
          We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy,
           unless a longer retention period is required or permitted by law.
          </p>
          <br/>
          <h1 class="text-1xl  mt-1 font-extrabold font-poppins sm:text-xl sm:font-extrabold">  
            Your Data Protection Rights
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            Depending on your location, you may have the following rights regarding your personal information:
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. Access: You have the right to request a copy of the personal information we hold about you.
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. Correction: You have the right to request that we correct any inaccuracies in your personal information.
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. Deletion: You have the right to request that we delete your personal information, under certain conditions.
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. Restriction: You have the right to request that we restrict the processing of your personal information,
             under certain conditions.
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. Objection: You have the right to object to our processing of your personal information, under certain conditions.
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. Portability: You have the right to request that we transfer the information 
            we have collected to another organization, or directly to you, under certain conditions.
          </p>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            To exercise any of these rights, please contact us at support@byson.finance.
          </p>
          <br/>
          <h1 class="text-1xl mt-1 font-extrabold font-poppins sm:text-xl sm:font-extrabold">  
           Children's Privacy
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
          Our Platform is not intended for use by individuals under the age of 18. We do not knowingly collect personal 
          information from children under 18. If we become aware that we have inadvertently collected such information, 
          we will take steps to delete it.
          </p>
          <br/>
          <h1 class="text-1xl mt-1 font-extrabold font-poppins sm:text-xl sm:font-extrabold">  
           Changes to This Privacy Policy
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new 
          Privacy Policy on our Platform. You are advised to review this Privacy Policy periodically for any changes. 
          Changes to this Privacy Policy are effective when they are posted on this page.
          </p>
          <br/>
          <h1 class="text-1xl mt-1 font-extrabold font-poppins sm:text-xl sm:font-extrabold">  
          Contact Us
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
          If you have any questions or concerns about this Privacy Policy, please contact us at support@byson.finance.
          </p>
          <br/>
            
         </div>
        </div>
      );
    }
  



  export default Policy;
