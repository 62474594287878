import React from "react";
import '../App.css';
import Navbar from "../Components/Navbar";







function Tou() {
      return (
        <div>
         <Navbar/>
         <div class=" text-justify p-1 m-1 ml-2 mr-2 sm:p-5 sm:m-3 sm:ml-5 sm:mr-5 ">
          <h1 class="text-1xl  mt-1 font-extrabold font-poppins sm:text-3xl sm:font-extrabold">  
              Byson Finance Terms and Conditions 
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">These Terms and Conditions ("Terms") govern your access to and use of the cryptocurrency 
          exchange platform, Byson ("Platform"), which enables frictionless crypto 
          swapping with social logins and zero gas fees. By using our Platform, you agree to these Terms. If you do not agree, please do not use our Platform.
          </p>
          <br/>
          <h1 class="text-1xl  mt-1 font-extrabold font-poppins sm:text-2xl sm:font-extrabold">  
            Eligibility 
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
          To use Byson.Finance, you must be at least 18 years old and legally capable of entering into binding contracts. 
          By using Byson, you represent and warrant that you meet these requirements.
          </p>
          <br/>
          <h1 class="text-1xl  mt-1 font-extrabold font-poppins sm:text-2xl sm:font-extrabold">  
            Account Registration 
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            1.  Social Login: You may create an account using your existing social media credentials. By doing so, you grant Byson the permission
           to access certain information from your social media profile, in accordance with our Privacy Policy.
          </p>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            2.  Accuracy: You agree to provide accurate and complete information during 
            the registration process and to update your information as necessary to keep it accurate and complete.
          </p>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            3.  Security: You are responsible for maintaining the confidentiality of your account credentials and for all activities 
            that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
          </p>
          <br/>
          <h1 class="text-1xl  mt-1 font-extrabold font-poppins sm:text-2xl sm:font-extrabold">  
           Use of the Platform 
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            1.  Permitted Uses: You may use the Platform to swap cryptocurrencies
             as permitted by these Terms and any applicable laws and regulations.
          </p>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            2.  Prohibited Uses: You agree not to:
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. Use the Platform for any unlawful purpose or in any manner that could 
            damage, disable, overburden, or impair the Platform.
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. Use automated scripts to collect information or interact with the Platform
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. Engage in any activity that could interfere with or 
            disrupt the Platform or the servers and networks connected to the Platform.
          </p>
          <p class="mt-2 ml-3 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            #. Violate any applicable laws or regulations.
          </p>
          <br/>
          <h1 class="text-1xl  mt-1 font-extrabold font-poppins sm:text-2xl sm:font-extrabold">  
            Transactions 
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            1.  Swapping Cryptocurrencies: Byson.Finance allows you to swap supported cryptocurrencies.
             You are responsible for ensuring that you have sufficient funds in your account to complete any transaction.
          </p>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            2.  Zero Gas Fees: Byson.Finance covers gas fees associated with transactions on the Platform. 
            However, we reserve the right to modify or discontinue this feature at any time without notice.
          </p>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            3.  Transaction Limits: We may impose limits on the amount and frequency 
            of transactions you can conduct on the Platform.
          </p>
          <br/>
          <h1 class="text-1xl  mt-1 font-extrabold font-poppins sm:text-2xl sm:font-extrabold">  
            Fees 
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
           While Byson.Finance does not charge gas fees, other fees may apply to certain transactions or services.
           All applicable fees will be disclosed to you prior to completing a transaction.
          </p>
          <br/>
          <h1 class="text-1xl  mt-1 font-extrabold font-poppins sm:text-2xl sm:font-extrabold">  
            Risk Disclosure 
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            1.  Market Volatility: Cryptocurrency markets are highly volatile. Prices can fluctuate significantly, 
            and you acknowledge that you are fully aware of these risks.
          </p>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            2.  No Investment Advice: Byson.Finance does not provide investment, financial, or trading advice. 
            Any decision to buy, sell, or swap cryptocurrencies is your own, and you assume full responsibility for all transactions.
          </p>
          <br/>
          <h1 class="text-1xl  mt-1 font-extrabold font-poppins sm:text-2xl sm:font-extrabold">  
           Privacy 
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
          Your use of the Platform is also governed by our Privacy Policy, 
          which outlines how we collect, use, and protect your personal information.
          </p>
          <br/>
          <h1 class="text-1xl  mt-1 font-extrabold font-poppins sm:text-2xl sm:font-extrabold">  
           Intellectual property 
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
          All content on the Platform, including text, graphics, logos, and software, is the property of Byson.Finance or its licensors
           and is protected by intellectual property laws. You may not use, reproduce, or distribute any content without our prior written permission.
          </p>
          <br/>
          <h1 class="text-1xl  mt-1 font-extrabold font-poppins sm:text-2xl sm:font-extrabold">  
           Termination 
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
          We reserve the right to terminate or suspend your account and access to the Platform at our sole discretion,
           without notice, and for any reason, including if we believe you have violated these Terms.
          </p>
          <br/>
          <h1 class="text-1xl  mt-1 font-extrabold font-poppins sm:text-2xl sm:font-extrabold">  
           Limitation of Liability
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
           To the fullest extent permitted by law, Byson.Finance, its affiliates, and their respective officers, directors, 
           employees, and agents will not be liable for any indirect, incidental, special, consequential, or punitive damages, 
           including but not limited to loss of profits, data, use, or goodwill, arising out of or related to your use of the Platform.
          </p>
          <br/>
          <h1 class="text-1xl  mt-1 font-extrabold font-poppins sm:text-2xl sm:font-extrabold">  
            Indemnification
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
          You agree to indemnify and hold Byson.Finance, its affiliates, and their respective officers, directors, employees, 
          and agents harmless from any claims, liabilities, damages, losses, and expenses, including reasonable attorneys'
          fees, arising out of or in any way connected with your use of the Platform or violation of these Terms.
          </p>
          <br/>
          <h1 class="text-1xl  mt-1 font-extrabold font-poppins sm:text-2xl sm:font-extrabold">  
           Governing Law
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
          These Terms are governed by and construed in accordance with the laws of the jurisdiction in which
           Byson.Finance operates, without regard to its conflict of law principles.
          </p>
          <br/>
          <h1 class="text-1xl  mt-1 font-extrabold font-poppins sm:text-2xl sm:font-extrabold">  
           Changes to Terms
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
          We may modify these Terms at any time. We will notify you of any changes by posting the revised Terms on
          the Platform. Your continued use of the Platform after such changes constitutes your acceptance of the new Terms.
          </p>
          <br/>
          <h1 class="text-1xl  mt-1 font-extrabold font-poppins sm:text-2xl sm:font-extrabold">  
           Contact Us
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">
            If you have any questions or concerns about these Terms, please contact us at support@byson.finance.
          </p>
          <br/>

  
            
         </div>
        </div>
      );
    }
  



  export default Tou;

