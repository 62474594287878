import React, { useState, useEffect } from 'react';
import '../App.css';
import Navbar from "../Components/Navbar";
import Swapby from "../Images/swapby1.png";
import Cards from "../Images/cryptocards.png";
import SocialLogin from "../Images/SocialLogin.png";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import CountUp from 'react-countup';


function Homepage() {

  const navigate = useNavigate(); 

  

  return (
    <div >
      <Navbar/>
      <div class="flex flex-col m-5 mt-10 mb-10  md:flex md:flex-row  sm:flex sm:flex-row ">
        <div class="basis-1/2 pt-7 mt-16 ml-7 sm:ml-14">
          
          <button class="flex flex-row  border-dark-green rounded-full  p-1 text-byson-blue bg-light-byblue">
             
            <p class="pl-2 pr-2 pt-0.5 font-light text-sm"> 💸. Explore the seemless token swap </p>
           
          </button>
          <h1 class="text-3xl mt-1 font-extrabold font-poppins sm:text-6xl sm:font-extrabold">  
            The Future of DeFi is Here:Join the Waitlist  to <span class="text-byson-blue">Enjoy the  Gasless</span> experience 
          </h1>
          <p class="mt-2 font-light text-sm sm:mt-1.7 sm:mb-1 ">Experience frictionless crypto swapping with social logins and zero gas fees.
          </p>
          <div class="flex flex-row content-center ">
           
           <button onClick={() => navigate('/Login')} class="bg-byson-blue text-white  p-1  p-2 mb-5 w-60 rounded-lg">
              Join Wait list!Get $10
           </button>
           
          </div>
        </div>
        <div class="basis-1/2 content-center ml- pl- pt- ">
         <div class="grid place-self-center sm:mt-9  ">
            <img class="place-self-center sm:w-70 sm:h-70  md:w-70 md:h-70" src={"https://res.cloudinary.com/mymently/image/upload/v1716244766/bytokens_lbp1ua.png"} alt="d" width="60%" height="" />   
         </div>
        </div>
      </div>
      <div class="flex flex-row bg-dark-green mt-10 mb-10"> 
      </div>
      <div class="flex flex-row bg-dark-green mt-10 mb-10"> 
      </div>

{/*Description*/}



{/* <div class=" grid text-center text-justify p-5 m-3 ml-5 mr-5 ">
          <h1 class="text-3xl text-center mt-1 font-extrabold font-poppins sm:text-3xl sm:font-extrabold">  
             DeFi Made Easy 
          </h1>
          <p class="mt-2 text-center font-light text-sm sm:mt-1.7 sm:mb-1 ">Decentralised exchange with a smoth and seemless web2 experience on the base blockchain
          </p>
          <img  class="place-self-center" src={Base} alt="d" width="12%" height="" />
         
           
            
         </div> */}


{/*New Why Use Byson*/}

     <div class=" text-center text-justify p-5 m-3 ml-5 mr-5 ">
          <h1 class="text-3xl text-center mt-1 font-extrabold font-poppins sm:text-5xl sm:font-extrabold">  
           
            Why Use <span class="text-byson-blue">Byson <br/>Finance</span> 🤙🏻 
   
          </h1>
          
     </div>



{/*Card1 */}

  <div class="grid grid-cols-6 gap-2 sm:gap-2  text-card-grey ">
            
           <div class="grid col-start-1 col-span-6 shadow-lg p- m-5 sm:col-start-2 sm:col-span-4  sm:p-5 sm:m-5 rounded-3xl bg-lcard-grey">
           <div class="flex flex-col m-5 mt-10 mb-10  md:flex md:flex-row  sm:flex sm:flex-row ">


                
           <div class="basis-1/2  ml- pl- pt- mt-4 sm:mt-0 sm:ml-2 align-middle content-center">
         <div class="grid place-self-center sm:mt- grid-cols-1 gap-4  ">
         <div class="grid p-1 pl-3  align-middle ">
           <div class="flex"> 
            <div>
              <h1 class="text-3xl  align-middle font-semibold font-poppins sm:text-1xl sm:font-bold">  
               Gasless Swaps 
              </h1>
              <p>
               Trade Crypto Without Breaking the Bank.<br/>
               Ditch the gas fees! Swap tokens seamlessly across any network with Byson's innovative technology. Save money and focus on growing your crypto portfolio.
              </p>
             
              
             </div>
            
           </div>
 
            </div>      
            
         </div>
         
        </div>


        <div class="basis-1/2  ml- pl- pt- mt-4 sm:mt-0 sm:ml-2">
         <div class="grid  sm:mt-9 grid-cols-1 gap-4 ">
         <div class="grid p-1 pl-3  rounded-lg ">
           <div class="flex"> 
            <div class="grid justify-items-center ">
            <img class="place-self-center sm:w-70 sm:h-70  md:w-70 md:h-70" src={Swapby} alt="d" width="100%" height="" />    
            </div>
            
           </div>
 
            </div>
                 
         </div>
         
        </div>
        
      </div>

    
        </div>
      </div>

{/*Card 2*/}

<div class="grid grid-cols-6 gap-2 sm:gap-2  text-card-grey ">
            
           <div class="grid col-start-1 col-span-6 shadow-lg p- m-5 sm:col-start-2 sm:col-span-4  sm:p-5 sm:m-5 rounded-3xl bg-lcard-grey">
           <div class="flex flex-col m-5 mt-10 mb-10  md:flex md:flex-row  sm:flex sm:flex-row ">


                
           <div class="basis-1/2  ml- pl- pt- mt-4 sm:mt-0 sm:ml-2 align-middle content-center">
         <div class="grid place-self-center sm:mt- grid-cols-1 gap-4  ">
         <div class="grid p-1 pl-3  align-middle ">
           <div class="flex"> 
            <div>
              <h1 class="text-3xl  align-middle font-semibold font-poppins sm:text-1xl sm:font-bold">  
                Crypto Card 
              </h1>
            <p>
               Spend Your Crypto Anywhere, Anytime.
               Convert your crypto to cash instantly with the Byson Crypto Card. Easily fund your card and withdraw money to your bank account 
             </p>
             
              
             </div>
            
           </div>
 
            </div>      
            
         </div>
         
        </div>


        <div class="basis-1/2  ml- pl- pt- mt-4 sm:mt-0 sm:ml-2">
         <div class="grid  sm:mt-9 grid-cols-1 gap-4 ">
         <div class="grid p-1 pl-3  rounded-lg ">
           <div class="flex"> 
            <div class="grid justify-items-center ">
            <img class="place-self-center sm:w-70 sm:h-70  md:w-70 md:h-70" src={Cards} alt="d" width="100%" height="" />    
            </div>
            
           </div>
 
            </div>
                 
         </div>
         
        </div>
        
      </div>

    
        </div>
      </div>

{/*card 3*/}

<div class="grid grid-cols-6 gap-2 sm:gap-2  text-card-grey ">
            
           <div class="grid col-start-1 col-span-6 shadow-lg p- m-5 sm:col-start-2 sm:col-span-4  sm:p-5 sm:m-5 rounded-3xl bg-lcard-grey">
           <div class="flex flex-col m-5 mt-10 mb-10  md:flex md:flex-row  sm:flex sm:flex-row ">


                
           <div class="basis-1/2  ml- pl- pt- mt-4 sm:mt-0 sm:ml-2 align-middle content-center">
         <div class="grid place-self-center sm:mt- grid-cols-1 gap-4  ">
         <div class="grid p-1 pl-3  align-middle ">
           <div class="flex"> 
            <div>
              <h1 class="text-3xl  align-middle font-semibold font-poppins sm:text-1xl sm:font-bold">  
               Social Logins
              </h1>
              <p>
               Simplify Your Crypto Journey.
               Forget complex sign-ups. Use your existing email or social login to access Byson's platform. Start trading and managing your crypto with ease.
              </p>
             
              
             </div>
            
           </div>
 
            </div>      
            
         </div>
         
        </div>


        <div class="basis-1/2  ml- pl- pt- mt-4 sm:mt-0 sm:ml-2">
         <div class="grid  sm:mt-9 grid-cols-1 gap-4 ">
         <div class="grid p-1 pl-3  rounded-lg ">
           <div class="flex"> 
            <div class="grid justify-items-center ">
            <img class="place-self-center sm:w-70 sm:h-70  md:w-70 md:h-70" src={SocialLogin} alt="d" width="100%" height="" />    
            </div>
            
           </div>
 
            </div>
                 
         </div>
         
        </div>
        
      </div>

    
        </div>
      </div>


{/* Why Use Byson */}

    {/* <div class="flex flex-col m-3 mt-10 mb-1  md:flex md:flex-row  sm:flex sm:flex-row sm:m-0 ">
      <div class="basis-1/2 ml-3 justify-center content-center p-10">
          <h1 class="text-3xl mt-1 m-2 font-extrabold font-poppins sm:text-4xl sm:font-extrabold">  
            Why Use <span class="text-byson-blue">Byson <br/>Finance</span> 🤙🏻 
          </h1>
          <div class="flex items-center p- m- bg-white/70 rounded-lg p-4 pl-5 pr-5 sm: ">
            <img class="place-self-center" src={Login} alt="d" width="12%" height="" />
             <div class="pl-2">
               <strong>Effortless Social Logins</strong>
               <p class="font-light text-sm">Ditch the passwords! Securely log in with your favorite social media account</p>
            </div>
         </div>
         <div class="flex items-center p- m- bg-white/70 rounded-lg p-4 pl-5 pr-5  ">
            <img class="place-self-center" src={Gasless} alt="d" width="12%" height="" />
             <div class="pl-2">
               <strong>Gasless Swapping</strong>
               <p class="font-light text-sm">Say goodbye to hefty transaction fees! Our innovative solution allows for seamless swaps without gas limitations</p>
            </div>
         </div>
         <div class="flex items-center p- m- bg-white/70 rounded-lg p-4 pl-5 pr-5  ">
            <img class="place-self-center" src={Shield} alt="d" width="12%" height="" />
             <div class="pl-2">
               <strong>Unparalleled Security</strong>
               <p class="font-light text-sm">Experience top-notch security protocols to ensure the safety of your crypto assets.</p>
            </div>
         </div>
         <div class="flex items-center p- m- bg-white/70 rounded-lg p-4 pl-5 pr-5  ">
            <img class="place-self-center" src={Perks} alt="d" width="12%" height="" />
             <div class="pl-2">
               <strong>Early Access Perks</strong>
               <p class="font-light text-sm">Be among the first to explore our platform and enjoy exclusive benefits upon launch.</p>
            </div>
         </div>
         
      </div>
      
      <div class="basis-1/2 flex  m-1 mt-5 mb-5   md:flex md:flex-row  sm:flex sm:flex-row justify-end">
        
         <div class="grid place-self-center p- m-  ">
            <img class="place-self-center" src={Swap} alt="d" width="70%" height="" />   
         </div>
        
      </div>
    </div> */}


{/* Join */}
 
        <div class=" text-center text-justify p-5 m-3 ml-5 mr-5 ">
          <h1 class="text-3xl text-center mt-1 font-extrabold font-poppins sm:text-5xl sm:font-extrabold">  
            Join over, 
            <CountUp   start={1000} end={31739} delay={1} duration={3.75}>
              {({ countUpRef }) => (
              <div class="text-byson-blue">
              <span ref={countUpRef} />+
             </div>
               )}
            </CountUp> 
            
            on our Waitlist 
          </h1>
          <p class="mt-2 text-center font-light text-sm sm:mt-1.7 sm:mb-1 ">Secure Your Spot for Early Access
          </p>
          <label class=" grid justify-items-center ">
           
          <button onClick={() => navigate('/Login')} class="bg-byson-blue text-white  p-1  p-2 mb-5 w-60 rounded-lg">
              Join Wait list!Get $10
           </button>
          </label>
          
          
            
         </div>
         
  

  

    </div>
  );
}

export default Homepage;