import React from 'react';
import { BrowserRouter, Routes,  Route } from 'react-router-dom';
import './App.css';
import Homepage from './Pages/Homepage';
import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import Tou from './Pages/Tou';
import Policy from './Pages/Policy'


function App() {
  return (
    <div className="App">
     <BrowserRouter>
       <Routes>
         <Route index element={<Homepage/>} />
         <Route path='/Login' element={<Login/>} />  
         <Route path='/Dashboard' element={<Dashboard/>} />
         <Route path='/tou' element={<Tou/>}/>
         <Route path='/policy' element={<Policy/>}/>
        
       </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
