import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../App.css';
import Navbar from "../Components/Navbar";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook

const EMAIL_API = 'https://app.xuperauth.com/api/v1/customer/send-code';
const VERIFY_API = 'https://app.xuperauth.com/api/v1/customer/verify-code';
const API_KEY = 'test_eab2e58a5809cebee463a3a96e4a19b65226f7f8b6a2b9a739de216bbf05b5a3';

function Login() {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isSubmittingEmail, setIsSubmittingEmail] = useState(false); // State for email submission
  const [showCodeInput, setShowCodeInput] = useState(false); // State for OTP field visibility
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate(); // useNavigate hook instance

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleSubmitEmail = async (event) => {
    event.preventDefault();
    setErrorMsg('');
    setIsSubmittingEmail(true); // Disable "Send Email" button

    try {
      const response = await axios.post(EMAIL_API, { email }, {
        headers: { 'x-superauth-key': API_KEY },
      });
      console.log('Email sent response:', response.data);
      setShowCodeInput(true); // Show OTP field
    } catch (error) {
      console.error('Error sending email:', error);
      setErrorMsg('An error occurred. Please try again later.');
    } finally {
      setIsSubmittingEmail(false); // Re-enable "Send Email" button
    }
  };

  const handleSubmitCode = async (event) => {
    event.preventDefault();
    setErrorMsg('');
    setIsSubmittingEmail(true); // Disable "Verify OTP" button
  
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
        
      const response = await axios.post(VERIFY_API, { email, code }, {
        headers: { 'x-superauth-key': API_KEY },
      });
      console.log('OTP verification response:', response.data);
  
      // Check for "status": true property
      if (response.data.status === true) {
        const userData = response.data.data; // Access user data (assuming data is in 'data' property)
        localStorage.setItem('userData', JSON.stringify(userData)); // Store data
        navigate('/dashboard'); // Redirect to dashboard on successful verification
      } else {
        setErrorMsg('Invalid OTP. Please try again.');
      }
        
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setErrorMsg('Invalid OTP. Please try again.');
    } finally {
      setIsSubmittingEmail(false); // Re-enable "Verify OTP" button
    }
  };

  return (
    <div>
      <Navbar/>
      <div class="grid grid-cols-6 gap-2 ">
        <div class="grid col-start-1 col-span-6 shadow-lg p-5 m-5 sm:col-start-2 sm:col-span-4 justify-items-center rounded-lg">
        <h1 class="text-3xl text-center mt-1 mb-5 font-extrabold font-poppins sm:text-5xl sm:font-extrabold">  
            Join our Waitlist 
          </h1>
          <form onSubmit={handleSubmitEmail} disabled={isSubmittingEmail}>
        <label htmlFor="email">Email:</label>
        <input
          class="border w-full mt-2 mb-2 p-1 rounded-md "
          type="email"
          id="email"
          value={email}
          onChange={handleEmailChange}
          required
        />
        {/* Button conditionally disabled */}
        { !showCodeInput && <button
          className={`bg-byson-blue text-white p-1 p-2 mb-5 w-full rounded-lg ${isSubmittingEmail ? 'loading' : ''}`}
          type="submit"
          disabled={isSubmittingEmail}>
         {isSubmittingEmail ? (
         <span className="loading-icon">Loading...</span>
          ) : (
        <span>CONTINUE</span>
         )}
        </button>}
       </form>

       {showCodeInput && (
        <form onSubmit={handleSubmitCode}>
          <label htmlFor="otp">OTP:</label>
          <input 
           class="border w-full mt-2 mb-2 p-1 rounded-md "
           type="text" id="otp" value={code} onChange={handleCodeChange} required />
          <button
           className={`bg-byson-blue text-white p-1 p-2 mb-5 w-full rounded-lg ${isSubmittingEmail ? 'loading' : ''}`}
           type="submit"
            disabled={isSubmittingEmail}
            >
            {isSubmittingEmail ? (
            <span className="loading-icon">Verifying...</span>
              ) : (
            <span>VERIFY OTP</span>
            )}
           </button>
        </form>
      )}

      {errorMsg && <p className="error">{errorMsg}</p>}
      {/* {isVerified && < Dashboard />} */}
        </div>
      </div>

      
      <div class="grid   grid-cols-none sm:grid-cols-2 m-3 justify-items-center">
       <div class=" ">
        <a href='/tou'>
          <p class="mt-2 text-center font-light text-sm sm:mt-1.7 sm:mb-1 ">
            Terms of Use
          </p></a>
       </div>
       <div class=" ">
       <a href='/policy'>
          <p class="mt-2 text-center font-light text-sm sm:mt-1.7 sm:mb-1 ">
            Policy
          </p></a>
       </div>
       
      </div>




    </div>
  );
}

export default Login;